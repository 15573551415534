.main {
  min-width: 1100px;
  max-height: 1013px;
  margin: 0 auto;
  background: #000;
  height: 100%;
  width: 100%;
}
.content {
  padding: 0 3.2%;
  max-width: 1800px;
}
